:root {
  --color-bg-lighter: #2a3553;
  --color-bg-darker: #17243d;
  --color-text: #ffffff;
  --color-text-muted: rgba(255, 255, 255, 0.7);
  --color-highlight: #3aedfe;
  --color-highlight-green: #53ffba;
  --color-highlight-red: #fe5182;
  --color-muted: #747c93;
  --color-muted-dark: #575c6d;

  --font-weight-light: 400;
  --font-weight-bold: 700;
}

body {
  padding: 30px;
  background-image: linear-gradient(
    var(--color-bg-lighter),
    var(--color-bg-darker)
  );
  color: var(--color-text);
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  min-height: 100vh;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.header {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--color-muted);
}

.header .headline-current-date {
  margin-bottom: 10px;
  color: var(--color-text-muted);
}

.header .headline {
  font-size: 25px;
  font-weight: var(--font-weight-light);
}

.section-worldwide {
  margin-bottom: 40px;
}

.subheadline-wrapper {
  margin-bottom: 10px;
}

.subheadline {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: var(--font-weight-light);
  margin-bottom: 10px;
  border: 1px solid var(--color-muted);
  border-radius: 5px;
  padding: 3px 5px;
}

.numbers {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 2px 0px;
}

.cases-label {
  font-weight: var(--font-weight-bold);
  font-size: 14px;
}

.accumulated-cases {
  font-size: 30px;
  color: var(--color-highlight);
  font-weight: var(--font-weight-light);
}

.recovered-cases {
  font-size: 20px;
  color: var(--color-highlight-green);
  font-weight: var(--font-weight-light);
}

.death-cases {
  font-size: 20px;
  color: var(--color-highlight-red);
  font-weight: var(--font-weight-light);
}

.last-update {
  margin-top: 25px;
  font-size: 14px;
  color: var(--color-text-muted);
}

.date-human-readable {
  font-size: 12px;
}

.section-land {
  margin-bottom: 40px;
}

.select-land-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.select-land-text {
  font-weight: var(--font-weight-light);
  font-size: 14px;
}

.select-land-dropdown,
.select-land-dropdown:visited {
  background: var(--color-muted-dark);
  border-radius: 5px;
  padding: 5px 8px;
  color: var(--color-text);
  outline: none;
}

.select-land-dropdown:hover {
  cursor: pointer;
}

.select-land-dropdown-wrapper {
  position: relative;
}

.select-land-dropdown-wrapper::before {
  content: '\2193';
  font-size: 20px;
  position: absolute;
  right: 7px;
  top: 0px;
  padding-left: 5px;
  color: var(--color-text);
}

.top-margin {
  margin-top: 20px;
}

.footer {
  padding: 20px 0px;
  border-top: 1px solid var(--color-muted);
  font-size: small;
}
